import { css } from "@emotion/core"
import { btn } from "css/primitives"
import * as resource from "css/resource"
import { colors } from "css/theme"
import RemoveIcon from "images/svg/icons/remove.svg"
import React, { useState } from "react"
import Modal from "react-modal"
import { If } from "src/components"
import useHubspotForm from "src/hooks/use-hubspot-form"
import * as util from "src/util"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    maxHeight: "100%",
  },
}

const modal = css`
  z-index: 10;
  &:focus {
    outline: none;
  }
  border-radius: 8px;
`

const modalButton = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    display: block;
    transition: box-shadow 0.3s ease;
    &:hover {
      color: white;
      box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.3);
    }
  `,
]

const modalDescription = css`
  max-width: 460px;
  font-size: 18px;
  margin-top: -1rem;
  line-height: 1.77;
  opacity: 0.75;
  margin-bottom: 20px;
  a {
    color: ${colors.orange};
  }
`

const formModalWrapper = css`
  width: 460px;
  overflow: auto;
  &:focus {
    outline: none;
  }
  @media (max-width: 479px) {
    width: 95vw;
    & > div {
      width: 100% !important;
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const closeButton = css`
  box-shadow: none;
  border: 0;
  background-color: transparent;
  fill: rgba(0, 0, 0, 0.7);
  display: inline-block;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  padding: 0.5rem;
  top: 0.5rem;
  right: 0.5rem;
  svg {
    transition: 0.2s ease fill;
  }
  &:hover,
  &:focus {
    fill: black;
    outline: none;
  }
`

export default function ResourceModal({ closeModal }) {
  const resourceLink = "/assets/uploads/2021/04/Smokescreen-Feature-List.pdf"
  const [hasSubmitted, setSubmitted] = useState(false)
  const { error } = useHubspotForm({
    formId: "d4c7b094-22bc-4c5b-8582-04b5a9eb1048",
    target: "#full-feature-list-form",
    onFormSubmitted: () => {
      util.trackEvent("ANY_FORM_SUBMISSION", "Submit")
      window.location.href = resourceLink
      setSubmitted(true)
    },
    onFormReady: () => {
      document.querySelector(
        '#full-feature-list-form input[name="resource_downloaded"]'
      ).value = "Smokescreen All Features List"
    },
  })
  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel="Resource Modal"
      css={modal}
      style={customStyles}
      ariaHideApp={false}
    >
      <button type="button" onClick={closeModal} css={closeButton}>
        <RemoveIcon width={20} height={20} />
      </button>
      <div>
        <div id="form" css={formModalWrapper}>
          <div
            css={resource.formWrapper}
            style={hasSubmitted ? { display: "none" } : null}
          >
            <h3 css={resource.formHeading}>{"Get Full Features List"}</h3>
            <div id="full-feature-list-form" className="hs-form-wrapper">
              {error ? "Could not load form" : null}
            </div>
          </div>
          <If test={hasSubmitted}>
            <div css={resource.formWrapper}>
              <h2 css={resource.formHeading}>Thanks!</h2>
              <p css={modalDescription}>
                Hope you find this useful. Browse the rest of our website and
                continue learning.
              </p>
              <If test={resourceLink}>
                <p css={modalDescription}>
                  In case your download didn't begin,{" "}
                  <a href={resourceLink}>click here to download</a>
                </p>
              </If>
              <button
                type="button"
                css={modalButton}
                onClick={() => {
                  setSubmitted(false)
                  closeModal()
                }}
              >
                Continue Browsing
              </button>
            </div>
          </If>
        </div>
      </div>
    </Modal>
  )
}
