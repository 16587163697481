import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import { Top, Form } from "./components"
import Reviews from "./components/Reviews"
import Features from "./components/Features"

const pricing = css`
  background-color: ${colors.darkBackground};
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  color: white;
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const pricingInner = css`
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;
`

function Contact() {
  return (
    <div css={pricing}>
      <div>
        <Top />
        <div css={pricingInner}>
          <Form />
          <Reviews />
          <Features />
        </div>
      </div>
    </div>
  )
}

export default Contact
