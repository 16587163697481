import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import ogImage from "images/seo/pricing.png"

import Pricing from "../views/pricing"

const PricingPage = () => (
  <div>
    <SEO
      title="Pricing"
      description="Are you wondering how much Smokescreen's deception platform costs? We have flexible pricing plans that you can modify or cancel if you change your mind."
      image={ogImage}
    />
    <PageLayout options={{ headingBackground: false }}>
      <Pricing />
    </PageLayout>
  </div>
)

export default PricingPage
