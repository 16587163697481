import React, { useState } from "react"
import { css } from "@emotion/core"
import { Carousel, CarouselItem, CarouselControl } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import GartnerLogo from "images/pricing/gartner-peer-insights.png"

const wrapper = css`
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 75px;
  border-bottom: 1px solid white;
  margin-bottom: 82px;
  cursor: default;
  .carousel-control-prev-icon {
    transform: rotate(180deg);
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='%23FFFFFF' width='100pt' height='100pt' version='1.1' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m46.578 99.965c-1.6094-0.13672-3.5781-0.375-4.8359-0.58984-18.16-3.0547-33.109-15.727-39.117-33.164-1.1445-3.3164-1.9648-7.0078-2.4297-10.895-0.25391-2.1133-0.25391-8.5391 0-10.652 1.3828-11.59 6.1094-21.586 14.035-29.691 8.1406-8.3281 18.504-13.359 30.441-14.785 2.1133-0.25391 8.5391-0.25391 10.652 0 11.941 1.4258 22.301 6.4609 30.441 14.785 7.9258 8.1055 12.652 18.102 14.035 29.691 0.25391 2.1133 0.25391 8.5391 0 10.652-1.3828 11.59-6.1094 21.586-14.035 29.691-8.125 8.3125-18.527 13.371-30.395 14.781-1.25 0.14844-7.6133 0.27344-8.793 0.17188zm4.543-33.457l16.516-16.516-4.6172-4.6172c-2.5391-2.5391-4.6172-4.6602-4.6172-4.7148 0-0.054687-5.3438-5.4414-11.871-11.969l-11.871-11.871-2.4922 2.4922 30.68 30.68-30.68 30.68 1.1719 1.1719c0.64453 0.64453 1.1914 1.1719 1.2227 1.1719 0.027344 0 7.4805-7.4297 16.562-16.516z'/%3E%3C/svg%3E%0A");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='%23FFFFFF' width='100pt' height='100pt' version='1.1' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m46.578 99.965c-1.6094-0.13672-3.5781-0.375-4.8359-0.58984-18.16-3.0547-33.109-15.727-39.117-33.164-1.1445-3.3164-1.9648-7.0078-2.4297-10.895-0.25391-2.1133-0.25391-8.5391 0-10.652 1.3828-11.59 6.1094-21.586 14.035-29.691 8.1406-8.3281 18.504-13.359 30.441-14.785 2.1133-0.25391 8.5391-0.25391 10.652 0 11.941 1.4258 22.301 6.4609 30.441 14.785 7.9258 8.1055 12.652 18.102 14.035 29.691 0.25391 2.1133 0.25391 8.5391 0 10.652-1.3828 11.59-6.1094 21.586-14.035 29.691-8.125 8.3125-18.527 13.371-30.395 14.781-1.25 0.14844-7.6133 0.27344-8.793 0.17188zm4.543-33.457l16.516-16.516-4.6172-4.6172c-2.5391-2.5391-4.6172-4.6602-4.6172-4.7148 0-0.054687-5.3438-5.4414-11.871-11.969l-11.871-11.871-2.4922 2.4922 30.68 30.68-30.68 30.68 1.1719 1.1719c0.64453 0.64453 1.1914 1.1719 1.2227 1.1719 0.027344 0 7.4805-7.4297 16.562-16.516z'/%3E%3C/svg%3E%0A");
  }
  @media (max-width: 600px) {
    .carousel-control-prev,
    .carousel-control-next {
      width: 50px;
    }
  }
`

const heading = css`
  font-size: 34px;
  font-family: var(--heading);
  font-weight: bold;
  line-height: 1.53;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 60px;
  @media (max-width: 767px) {
    font-size: 28px;
  }
`

const carouselItem = css`
  text-align: center;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 850px) {
    max-width: 500px;
  }
  @media (max-width: 600px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

const tag = css`
  border-radius: 23px;
  box-shadow: 1px 1px 10px 0 rgba(140, 139, 139, 0.52);
  padding: 7px 30px;
  margin-bottom: 30px;
  background-image: linear-gradient(to right, #febb11, #ffffff);
  font-family: var(--heading);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.86;
  color: black;
  display: inline-block;
  text-transform: uppercase;
`

const quote = css`
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 6px;
  @media (max-width: 850px) {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

const role = css`
  font-size: 16px;
  line-height: 1.27;
  color: #e5e5e5;
`

const gartnerWrapper = css`
  text-align: center;
  margin-top: 21px;
`

const items = [
  {
    tag: "GAME CHANGER",
    quote:
      "Smokescreen has made our network hostile and unpredictable for attackers.",
    role: "Manager - Information Security, Manufacturing",
  },
  {
    tag: "More bang for your buck",
    quote:
      "Has greatly improved our detection capabilities. Is easy to implement and has given us excellent ROI.",
    role: "Information Security Manager, Banking and Finance",
  },
  {
    tag: "HAS YOUR BACK WHEN OTHERS DON’T",
    quote:
      "Helps us catch malicious actors that haven’t been detected by other security controls.",
    role: "Head of Information Security, Media Network",
  },
  {
    tag: "LOVE AT FIRST SIGHT",
    quote:
      "We took an instant liking to this product, right from the first demo.",
    role: "Infosec Manager, IT",
  },
]

export default function Reviews(props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.tag}
      >
        <div css={carouselItem}>
          <div css={tag}>{item.tag}</div>
          <div css={quote}>“{item.quote}”</div>
          <div css={role}>{item.role}</div>
        </div>
      </CarouselItem>
    )
  })

  return (
    <div css={wrapper}>
      <h2 css={heading}>What customers are saying</h2>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
      <div css={gartnerWrapper}>
        <img src={GartnerLogo} alt="Gartner Peer Insights" />
      </div>
    </div>
  )
}
