import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { h2 } from "css/primitives"

const wrapper = css`
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 100%
  );
  @media (max-width: 1025px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @media (max-width: 479px) {
    padding-top: 120px;
    padding-bottom: 150px;
  }
`

const heading = [
  h2,
  css`
    line-height: 72px;
  `,
]

function Top() {
  return (
    <div css={wrapper}>
      <h1 css={heading}>Request Pricing</h1>
    </div>
  )
}

export default Top
