import React, { useState } from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { btn } from "css/primitives"
import { If } from "src/components"

import CloudFirst from "images/svg/pricing/cloud-first.svg"
import Endpoint from "images/svg/pricing/endpoint.svg"
import Cloud from "images/svg/pricing/cloud.svg"
import Email from "images/svg/pricing/email.svg"
import Forensics from "images/svg/pricing/forensics.svg"
import Landmine from "images/svg/pricing/landmine.svg"
import Network from "images/svg/pricing/network.svg"
import Orchestration from "images/svg/pricing/orchestration.svg"
import ThreatParse from "images/svg/pricing/threatparse.svg"
import ActiveDirectory from "images/svg/pricing/active-directory.svg"
import EnterpriseFeatures from "images/svg/pricing/enterprise-features.svg"
import GoldenImage from "images/svg/pricing/golden-image.svg"
import MirageMaker from "images/svg/pricing/miragemaker.svg"
import OnPrem from "images/svg/pricing/on-prem.svg"
import SupportTraining from "images/svg/pricing/support-training.svg"
import ResourceModal from "./ResourceModal"

const wrapper = css`
  max-width: calc(1130px + 2rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
`

const heading = css`
  font-size: 34px;
  font-family: var(--heading);
  font-weight: bold;
  line-height: 1.53;
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    font-size: 28px;
  }
`

const featuresList = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1023px) {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 599px) {
    max-width: 300px;
  }
`

const featureItem = css`
  width: 100%;
  max-width: 278px;
  margin-bottom: 2.5rem;
  padding-right: 1rem;
  margin-right: 3rem;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  @media (max-width: 1023px) {
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    margin-right: 0;
  }
`

const featureItemInner = css`
  width: 278px;
  @media (max-width: 649px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const featureImage = css`
  margin-bottom: 2px;
  width: 30px;
  height: 30px;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const featureTitle = css`
  font-family: var(--heading);
  font-size: 18px;
  font-weight: bold;
  line-height: 3.11;
`

const featureDescription = css`
  font-size: 18px;
  line-height: 1.78;
`

const button = [
  btn,
  css`
    background-color: ${colors.orange};
    border: 1px solid ${colors.orange};
    color: white;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.9;
    }
    @media (max-width: 359px) {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `,
]

const featuresContent = [
  {
    image: Network,
    title: "Network Deception",
    description:
      "Server system decoys that host services like SSH servers, databases, file shares and more.",
  },
  {
    image: Endpoint,
    title: "Endpoint Deception",
    description:
      "A minefield for your endpoints. Includes decoy files, decoy credentials, decoy processes etc.",
  },
  {
    image: ActiveDirectory,
    title: "Active Directory Deception",
    description:
      "Fake users in active directory that detect enumeration activity and malicious access.",
  },
  {
    image: Cloud,
    title: "Cloud Deception",
    description:
      "Decoys web servers, databases, file servers, etc. that detect lateral movement in your cloud environments.",
  },
  {
    image: Email,
    title: "Email Deception",
    description:
      "Email decoys that intercept attackers attempting to mount social engineering/ spear-phishing attacks.",
  },
  {
    image: GoldenImage,
    title: "Golden Image Support",
    description:
      "Realistic high-interaction OS enviornments for deeper attack visibility.",
  },
  {
    image: MirageMaker,
    title: "MirageMaker",
    description:
      "Out-of-the-box decoy datasets for a variety of use cases to launch deception campaigns rapidly.",
  },
  {
    image: Landmine,
    title: "Landmine",
    description:
      "Simple deployment for decoy files, processes, and breadcrumbs on your endpoints.",
  },
  {
    image: ThreatParse,
    title: "ThreatParse",
    description:
      "Automated forensics and root-cause analysis in two clicks. Extracts insights from logs.",
  },
  {
    image: CloudFirst,
    title: "Cloud-First Architecture",
    description:
      "No hardware, zero maintenance hassles, always updated. Doesn’t take a village to implement.",
  },
  {
    image: OnPrem,
    title: "On-prem Options",
    description:
      "Ability to deploy on-prem or to your private cloud if that’s how your IT is set up.",
  },
  {
    image: EnterpriseFeatures,
    title: "Enterprise Features",
    description:
      "2FA, RBAC, log retention, automatic backups, SSO. API access, audit logs, SNMP monitoring, and more.",
  },
  {
    image: Orchestration,
    title: "Orchestration",
    description:
      "Integrations with SIEMS, firewalls, EDRs, NACs, and SOARs for automating inciudent response.",
  },
  {
    image: Forensics,
    title: "Forensics",
    description:
      "Identify the process that the attacker has used to engage with the decoy. Dig deeper with PCAPs.",
  },
  {
    image: SupportTraining,
    title: "Support and Training",
    description:
      "Access to a help desk, documentation, on-boarding sessions, and product training.",
  },
]

function Features() {
  const [isModalOpen, setModalOpen] = useState(false)
  return (
    <div css={wrapper}>
      <h2 css={heading}>All Plans Include…</h2>
      <div css={featuresList}>
        {featuresContent.map((feature) => {
          return (
            <div key={feature.title} css={featureItem}>
              <div css={featureItemInner}>
                <div css={featureImage}>
                  {feature.image && <feature.image />}
                </div>
                <h4 css={featureTitle}>{feature.title}</h4>
                <p css={featureDescription}>{feature.description}</p>
              </div>
            </div>
          )
        })}
      </div>
      <div className="text-center">
        <button css={button} type="button" onClick={() => setModalOpen(true)}>
          Download the full feature list
        </button>
      </div>
      <If test={isModalOpen}>
        <ResourceModal closeModal={() => setModalOpen(false)} />
      </If>
    </div>
  )
}

export default Features
